
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';

import { map, catchError, switchMap } from 'rxjs/operators';
import { interval as _interval  } from 'rxjs';



import { UtilService } from './util.service';
import { environment } from '../../environments/environment';
import { Device } from '../model/device';
import { DeviceTime } from '../model/deviceTime';
import { Distance } from '../model/distance';

@Injectable()
export class DevicesService {
	private adminDeviceUrl = environment.apiurl + '/location/getDevices';
	private userDeviceUrl = environment.apiurl + '/location/getUserDevices';
	private filterDeviceUrl = environment.apiurl + '/location/getAllDevices';

	private materialUrl = environment.apiurl + '/location/getDistinctMaterials';
	private deviceDistanceSeriesUrl = environment.apiurl + '/location/getSeries';
	private measurementUrl = environment.apiurl + '/location/takeMeasurement';
	private statusUrl = environment.apiurl + '/location/getDeviceStatusTime';

	private _isClassVisible = new BehaviorSubject<boolean>(true);
	public isClassVisible = this._isClassVisible.asObservable();
	public currentValue: boolean = false;
	public hasError: Subject<boolean> = new Subject<boolean>();
	public message: Subject<string> = new Subject<string>();
	public device_id: Subject<number> = new Subject<number>();

	private _devicesAdmin: BehaviorSubject<Device[]> = new BehaviorSubject<Device[]>([]);
	public devicesAdmin: Observable<Device[]> = this._devicesAdmin.asObservable();

	private _deviceAdmin: BehaviorSubject<Device[]> = new BehaviorSubject<Device[]>([]);
	public deviceAdmin: Observable<Device[]> = this._deviceAdmin.asObservable();

	public _devices: BehaviorSubject<Device[]> = new BehaviorSubject<Device[]>([]);
	public devices: Observable<Device[]> = this._devices.asObservable();

	private _device: BehaviorSubject<Device[]> = new BehaviorSubject<Device[]>([]);
	public device: Observable<Device[]> = this._device.asObservable();

	private _userDevices: BehaviorSubject<Device[]> = new BehaviorSubject<Device[]>([]);
	public userDevices: Observable<Device[]> = this._userDevices.asObservable();

	private _filtered_devices: BehaviorSubject<Device[]> = new BehaviorSubject<Device[]>([]);
	public filtered_devices: Observable<Device[]> = this._filtered_devices.asObservable();

	private _materials: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
	public materials: Observable<any[]> = this._materials.asObservable();

	// private _series: BehaviorSubject<Distance[]> 	= new BehaviorSubject<Distance[]>( [] );
	// public series: Observable<Distance[]>			= this._series.asObservable();

	private _deviceMeasurement: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
	public deviceMeasurement: Observable<Distance[]> = this._deviceMeasurement.asObservable();

	public _deviceTime: BehaviorSubject<DeviceTime[]> = new BehaviorSubject<DeviceTime[]>([]);
	public deviceTime: Observable<DeviceTime[]> = this._deviceTime.asObservable();

	constructor(private util: UtilService, private router: Router) {}

	public getDevicesAdmin(device_id?: number, location_id?: any, page?: number, recordsPerPage?: number): void {
		const payload: any = {};

		if(device_id) payload.device_id = device_id;
		if(location_id) payload.location_id = location_id.split(',');
		if(page) payload.page = page;
		if(recordsPerPage) payload.recordsPerPage = recordsPerPage;

		this.util
			._getData( this.adminDeviceUrl, payload )
			.pipe(map((ret) => ret.data))
			.subscribe((deviceJSON) => {
				const devices = deviceJSON.map((devicejson) => Device.fromJSON(devicejson));
				this._devicesAdmin.next(devices);
			});
	}

	public getDevicesAdminByLocationId(location_id?: any, group_id?: any, material?: ''): Observable<Device[]> {
		/*let _devices: Subject<Device[]> 	= new Subject<Device[]>();
		this.util._requestData( this.adminDeviceUrl+ '&location_id=' + location_id+ '&group_id=' + group_id  )		
			.map(ret=>ret.qry)
			.subscribe( deviceJSON =>{ 
				const devices = deviceJSON
									.map( devicejson => Device.fromJSON( devicejson ));
				_devices.next( devices );
			})
			return _devices;*/

		const payload: any = {};
		if(location_id) payload.location_id = Array.isArray(location_id) ? location_id : location_id.split(',');
		if(group_id) payload.group_id = group_id;
		if(material) payload.material = material;

		return this.util
			._getData(this.adminDeviceUrl, payload)
			.pipe(map((ret) => ret.data))
			.pipe(map((cJSON) => {
				const devices = cJSON.map((jsonobj) => Device.fromJSON(jsonobj));
				return devices;
			}));
	}

	public getDevicesListByUser(): Observable<Device[]> {
		return this.util
			._getData(environment.apiurl + '/location/getDeviceListByUser')
			.pipe(map((res) => {
				return res.data.map((devicejson) => Device.fromJSON(devicejson));
			}));
	}

	public getDeviceByIdAdmin(device_id?: number): void {
		this.util
			._getData(this.adminDeviceUrl, { device_id })
			.pipe(map((ret) => ret.data))
			.subscribe((deviceJSON) => {
				const device = deviceJSON.map((devicejson) => Device.fromJSON(devicejson));
				this._deviceAdmin.next(device);
			});
	}

	public getDevicesByLocation(loc_id?: number, dev_id?: number): void {

		const payload: any = {};
		if(loc_id) payload.location_id = loc_id;
		if(dev_id) payload.device_id = dev_id;

		this.util
			._requestData(this.filterDeviceUrl)
			.pipe(map((ret) => ret.data))
			.subscribe((deviceJSON) => {
				const devices = deviceJSON.map((devicejson) => Device.fromJSON(devicejson));
				this._devices.next(devices);
			});
	}

	public getFilteredDevices(obj?: any): void {
		
		const payload: any = {
			date: new Date().getTime()
		}

		
		if (obj.searchString) payload.searchString = obj.searchString;
		if (obj.groupID) payload.group_id = obj.groupID;
		if (obj.locationID) payload.location_id = obj.locationID;
		if (obj.deviceID) payload.device_id = obj.deviceID;
		if (obj.hasAlert) payload.hasAlert = obj.hasAlert;
		if (obj.page) payload.page = obj.page;
		if (obj.orderBy) payload.orderBy = obj.orderBy;
		if (obj.orderDirection) payload.orderDirection = obj.orderDirection;
		if (obj.recordsPerPage) payload.recordsPerPage = obj.recordsPerPage;

		this.util
			._getData(
				this.filterDeviceUrl, payload)
			.subscribe((res) => {
				// const devices = res.data;
				const devices = res.data.map((devicejson) => Device.fromJSON(devicejson));
				console.log("getFilteredDevices", devices.comm_error);
				// console.log("getFilteredDevices", devices);
				this._devices.next(devices);
			}, (error) => {
				console.log('Error getting filtered devices', error);
			});
	}

	public getDeviceByID(did?: number): void {

		const payload: any = {
			date: new Date()
		}

		if(did) {
			payload.device_id = did;
		}

		this.util
			._getData(this.filterDeviceUrl, payload)
			.pipe(map((ret) => ret.data))
			.subscribe((deviceJSON) => {
				console.log('deviceJSON123', deviceJSON);
				const device = deviceJSON.map((devicejson) => Device.fromJSON(devicejson));
				this._device.next(device);
			});
	}

	public returnDeviceByID(did?: number): Observable<Device[]> {
		// let _series: Subject<Distance[]> = new Subject<Distance[]>();
		const payload: any = {
			date: new Date().getTime(),
			time: 'time'
		}
		if(did) payload.device_id = did;
		return this.util
			._getData(this.filterDeviceUrl, payload)
			.pipe(map((ret) => ret.data))
			.pipe(map((deviceJSON) => {
				const device = deviceJSON.map((devicejson) => Device.fromJSON(devicejson));
				// _series.next( series );
				return device;
			}));
		// return _series;
	}

	// Not in the use
	// public getMaterials(group_id?, location_id?): void {
		
	// 	const payload: any = {
	// 		date: new Date().getTime(),
	// 	}
	// 	if(group_id) payload.group_id = group_id;
	// 	if(location_id) payload.location_id = group_id;

	// 	this.util
	// 		._getData(this.materialUrl, payload)
	// 		.pipe(map((ret) => ret.data))
	// 		.subscribe((materialJson) => {
	// 			const materials = materialJson.map((mtrl) => mtrl);
	// 			this._materials.next(materials);
	// 		});
	// }

	public getDeviceDistanceSeries(device_id: number, start?: number, end?: number): Observable<Distance[]> {
		const payload: any = {};
		
		if(device_id) payload.device_id = device_id;
		if(start) payload.start = start;
		if(end) payload.end = end;

		return this.util
			._getData(this.deviceDistanceSeriesUrl, payload)
			.pipe(map((ret) => ret.data))
			.pipe(map((distJSON) => {
				console.log('distJSON', distJSON)
				const series = distJSON.map((distjson) => Distance.fromJSON(distjson));
				return series;
			}));
	}

	public getDeviceSignalSeries(device_id: number, start?: number, end?: number): Observable<Distance[]> {
		const url = environment.apiurl + '/location/getSignalSeries';

		const payload: any = {
			date: new Date()
		};
		if(device_id) payload.device_id = device_id;
		if(start) payload.start = start;
		if(end) payload.end = end;

		return this.util
			._getData(url, payload)
			.pipe(map((ret) => ret.data))
			.pipe(map((distJSON) => {
				console.log('distJSON', distJSON)
				const series = distJSON.map((distjson) => Distance.fromJSON(distjson));
				return series;
			}));
	}

	public takeMeasurement(device_id, smu_timeStamp?): Observable<string> {
		let tmp = [];

		return this.util
			._getData(this.measurementUrl, {
				devices: device_id,
				date: new Date()
			})
			.pipe(map((ret) => ret.data))
			.pipe(map((res) => {
				if (res[0].status === 'success') {
					this.hasError.next(false);
					this.message.next('Measuring...');
					let _tmp: any = [];

					if (localStorage.getItem('measuring') && localStorage.getItem('measuring').length > 0) {
						_tmp = JSON.parse(localStorage.getItem('measuring'));
						// _tmp.push({'device_id':device_id,'smu_timeStamp':smu_timeStamp,'newTimeStamp':null})
						let foundDevice = false;
						for (let x of _tmp) {
							if (x.device_id == device_id) {
								foundDevice = true;
								x.smu_timeStamp = smu_timeStamp;
							}
						}
						if (!foundDevice) {
							_tmp.push({ device_id: device_id, smu_timeStamp: smu_timeStamp, newTimeStamp: null });
							localStorage.setItem('measuring', JSON.stringify(_tmp));
						}
						localStorage.setItem('measuring', JSON.stringify(_tmp));
					} else {
						_tmp.push({ device_id: device_id, smu_timeStamp: smu_timeStamp, newTimeStamp: null });
						localStorage.setItem('measuring', JSON.stringify(_tmp));
					}
				} else {
					this.hasError.next(true);
					this.message.next('There was an error taking measurement. Please try again.');
				}
				return res[0].status;
			}));
	}
	interval;
	public getDeviceListStatusTime(device_ids) {
		let tmp = [];
		const url = environment.apiurl + '/location/getDeviceListStatusTime';
		/*
		this.util._requestData( url + '&device_ids=' + device_ids + '&date=' +  new Date().getTime() )		
			.map(ret=>ret.qry)
			.subscribe(
						 res=>{
						 	const time = res.map(x=> DeviceTime.fromJSON(x));
						 	this._deviceTime.next(time);
						 },
						 (err)=>{
						 		this.hasError.next(true); 
						 		this.message.next('There was an error requeting status time. Please try again.');
						 		console.log('Error status time' + err);
						 	},
						 ()=>{}
					  );*/
		if (this.interval) {
			this.interval.unsubscribe();
		}
		this.interval = _interval(10000)
			.pipe(switchMap(() => this.util._getData(url, {
				device_ids,
				date: new Date().getTime()
			})))
			.subscribe(
				(res) => {
					const time = res.data.map((x) => DeviceTime.fromJSON(x));
					this._deviceTime.next(time);
				},
				(err) => {
					this.hasError.next(true);
					this.message.next('There was an error requeting status time. Please try again.');
					console.log('Error status time' + err);
				},
				() => {}
			);
	}

	public getDeviceStatusTime(device_id) {
		let tmp = [];
		const url = environment.apiurl + '/location/getDeviceStatusTime';

		this.util
			._getData(this.statusUrl, {
				date: new Date(),
				device_id
			})
			.pipe(map((ret) => ret.data))
			.subscribe(
				(res) => {
					// return 123;
					let _tmp = JSON.parse(localStorage.getItem('measuring'));
					console.log("362 _tmp", _tmp, res);
					for (let x of _tmp) {
						if (x.device_id == device_id) {
							x.newTimeStamp = res;
						}
					}
					localStorage.setItem('measuring', JSON.stringify(_tmp));
				},
				(err) => {
					this.hasError.next(true);
					this.message.next('There was an error requeting status time. Please try again.');
					console.log('Error status time' + err);
				},
				() => {
					this._deviceMeasurement.next(tmp);
				}
			);
	}

	public getUserDevices(user_id: number): void {
		this.util
			._getData(this.userDeviceUrl, {user_id})
			.subscribe((res) => {
				const uds = res.data.map((djson) => Device.fromJSON(djson));
				this._userDevices.next(uds);
			});
	}

	public changeView(v) {
		this._isClassVisible.next(v);
	}

	public saveDevice(body, device_id) {
		let url = environment.apiurl + '/location/saveDevice';
		this.util
			._postData(url, body)
			.pipe(map((ret) => ret.data))
			.subscribe(
				// ()=>this.router.navigateByUrl('/administration/silo/' + device_id + '/edit/' + device_id),
				(res) => {
					/*this.router.navigateByUrl('/administration/silo/' + res.device_id + '/edit/' + res.device_id)
									this.device_id.next(res.device_id || 0);*/
				},
				(err) => {
					console.log('Error saving Device', err);
					this.hasError.next(true);
					this.message.next(err.error.message);
				},
				() => {
					console.log('saving Device completed');
					this.hasError.next(false);
					this.message.next('Silo information is saved successfully.');
				}
			);
	}

	public deleteDevice(body) {
		let url = environment.apiurl + '/location/deleteDevice';
		this.util
			._postData(url, body)
			.subscribe(
				() => this.router.navigateByUrl('/administration/silos'),
				() => {
					console.log('Error deleting Rules');
				},
				() => console.log('deleting device completed')
			);
	}

	public getDevices(latestTimestamp?: number): Observable<Device[]> {

		const payload: any = {
			page: 1,
			recordsPerPage: 1000
		}

		if (latestTimestamp) payload.latestTimestamp = latestTimestamp.toString()

		return this.util
			._requestData(this.filterDeviceUrl)
			.pipe(map((ret) => ret.data))
			.pipe(map((deviceJSON) => {
				const devices = deviceJSON.map((devicejson) => Device.fromJSON(devicejson));
				// this._devices.next( devices );
				return devices;
			}));
	}

	public measureAll(devices): Observable<any> {
		let url = environment.apiurl + '/location/takeMeasurement';
		return this.util._getData(url, { devices, date: new Date() }).pipe(map((ret) => ret.qry));
	}

	public suspendMeasurement(device_id, isLocked): Observable<any> {
		let url = environment.apiurl + '/location/suspendMeasurement';
		const payload: any = {
			device_id,
			isLocked
		};

		return this.util._getData(url, payload);
	}
}
