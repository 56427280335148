import {
  Component,
  Input,
  OnInit,
  ElementRef,
  OnDestroy,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnChanges,
} from "@angular/core";
import { Observable } from "rxjs";

import { map } from "rxjs/operators";

import { Device } from "../model/device";
import { Distance } from "../model/distance";
import { DevicesService } from "../services/devices.service";

declare var require: any;
const Highcharts = require("highcharts/highcharts.src");

// import * as $ from 'jquery';
declare var jQuery: any;

import * as moment from "moment";
import {
  NgbCalendar,
  NgbDate,
  NgbInputDatepickerConfig,
  NgbPopoverConfig,
  NgbTimeStruct,
} from "@ng-bootstrap/ng-bootstrap";

// import { NguiDatetime } from '@ngui/datetime-picker';

// Override Date object formatter
// NguiDatetime.formatDate = (date: Date) : string => {
//     return moment( date ).format('MM-DD-YYYY HH:mm');
// };

@Component({
  selector: "app-highchart-example",
  templateUrl: "./highchart-example.component.html",
  styleUrls: ["./highchart-example.component.css"],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class HighchartExampleComponent implements OnInit, OnDestroy, OnChanges {
  @Input() device?: Device;
  @Input() open: any;
  @Input() id?: string = "";
  @ViewChild("chart") public chartEl: ElementRef;

  public series: Observable<any[]>;
  public signalSeries: Observable<any[]>;

  public deviceByID: Device;
  public loading: boolean = false;
  public data: any;

  public device_id: number;

  public d; //:Date = new Date();
  public dd; // = this.d.getTime() - (24 * 60 * 60 * 1000);/*Hour * min * sec*/
  endDate$;

  model: {
    startDate: {
      year?: number;
      month?: number;
      day?: number;
      hour?: number;
      minute?: number;
      displayDate?: any;
      isoDate?: any;
    };
    endDate: {
      year?: number;
      month?: number;
      day?: number;
      hour?: number;
      minute?: number;
      displayDate?: any;
      isoDate?: any;
    };
  } = {
    startDate: {},
    endDate: {},
  };
  //  = {
  //   startDate: new Date( this.dd ),
  //   endDate: this.d
  // }

  public ullageContainer: Device;
  public maxVolume: number;

  constructor(
    private devicesService: DevicesService,
    private changeDetectorRef: ChangeDetectorRef,
    private popOverConfig: NgbPopoverConfig,
    private config: NgbInputDatepickerConfig,
    private calendar: NgbCalendar
  ) {
    /*-------------Date Picker Config Initialize-------------*/
    // setting first day of week
    config.firstDayOfWeek = 7;

    // weekends are disabled
    config.markDisabled = (date: NgbDate) => calendar.getWeekday(date) >= 6;

    /*-------------Date Picker Config Initialize-------------*/

    /*-------------Popover Config Initialize-------------*/
    // setting datepicker popup to close only on click outside
    popOverConfig.autoClose = "outside";

    // setting datepicker popup to open above the input
    popOverConfig.placement = ["top-start", "top-end"];
    /*-------------Popover Config Initialize-------------*/

    this.d = new Date();
    this.dd = this.d.getTime() - 24 * 60 * 60 * 1000;

    let currentDate = {
      year: new Date(this.dd).getFullYear(),
      month: new Date(this.dd).getMonth(),
      day: new Date(this.dd).getDay(),
      hour: new Date(this.dd).getHours(),
      minute: new Date(this.dd).getMinutes(),
    };

    this.model.startDate = JSON.parse(JSON.stringify(currentDate));
    this.model.endDate = JSON.parse(JSON.stringify(currentDate));

    let displayDate = this.showDate(
      JSON.parse(JSON.stringify(currentDate)),
      false
    );
    let isoDate = this.showDate(JSON.parse(JSON.stringify(currentDate)));

    this.model.startDate.displayDate = JSON.parse(JSON.stringify(displayDate));
    this.model.endDate.displayDate = JSON.parse(JSON.stringify(displayDate));

    this.model.startDate.isoDate = JSON.parse(JSON.stringify(isoDate));
    this.model.endDate.isoDate = JSON.parse(JSON.stringify(isoDate));
  }

  private _chart: any;
  private _data: any = [];

  public showGraph() {
    let yAxisLabel = [
      `Distance (${this.device.linear_label})`,
      `Level (${this.device.linear_label})`,
      `Weight (${this.device.weight_label})`,
      `(Weight) (${this.device.weight_label})`,
      `Volume (${this.device.volume_label})`,
      `(Volume) (${this.device.volume_label})`,
      `Percent`,
      `(Percent)`,
      `Signal Strength (db)`,
    ];

    let opts: any = {
      title: {
        text: this.device.location_name,
      },

      subtitle: {
        text: "Silo: " + this.device.device_name,
      },

      xAxis: {
        type: "datetime",
        labels: {
          formatter: function () {
            return Highcharts.dateFormat("%H:%M <br>%m/%d/%y", this.value);
          },
        },
      },
      yAxis: {
        title: {
          text: "Level",
        },
        labels: {
          format: "{value:.2f}",
        },
        tickInterval: 1,
      },
      tooltip: {
        headerFormat: "<b>{series.name}</b><br>",
        pointFormat: "{point.x:%m/%d/%y %H:%M} <br>{point.y:f}",
      },
      legend: {
        layout: "horizontal",
        align: "left",
        verticalAlign: "bottom",
      },
      global: {
        useUTC: false,
      },
      credits: {
        enabled: false,
      },
      lang: { noData: "No Data. Please update search data interval" },
      plotOptions: {
        line: {
          marker: {
            enabled: false,
          },
          animation: false,
        },
        series: {
          events: {
            legendItemClick: function (event) {
              var selected = this.index;
              var allSeries = this.chart.series;

              jQuery.each(allSeries, function (index, series) {
                series.hide();
                if (selected == index) {
                  series.show();
                  this.chart.yAxis[0].update({
                    title: { text: yAxisLabel[index] },
                  });
                }
              });
              return false;
            },
          },
        },
      },
      series: [
        { name: "Distance", data: this.device.distanceSeries },
        { name: "Level", data: this.device.levelSeries },
        { name: "Weight", data: this.device.weightSeries },
        { name: "(Weight)", data: this.device.uWeightSeries },
        { name: "Volume", data: this.device.volumeSeries },
        { name: "(Volume)", data: this.device.uVolumeSeries },
        { name: "Percent", data: this.device.percentSeries },
        { name: "(Percent)", data: this.device.uPercentSeries },
        { name: `Signal Strength`, data: this.device.signalStrength },
      ],
    };

    opts.chart = {
      renderTo:
        this.chartEl?.nativeElement /*'newchart_' + this.device_id + this.id,*/,
      zoomType: "x",
    };
    this._chart = new Highcharts.Chart(opts);
    this._chart.series[0].hide();
    this._chart.series[1].hide();
    this._chart.series[2].hide();
    this._chart.series[3].hide();
    this._chart.series[4].hide();
    this._chart.series[5].hide();
    this._chart.series[6].hide();
    this._chart.series[7].hide();

    if (this.device.viewSignal()) {
      this._chart.series[8].hide();
    } else {
      this._chart.series[8].remove();
    }

    if (this.device.default_display_label.trim().toLowerCase() == "distance") {
      this._chart.series[0].show();
      this._chart.yAxis[0].update({
        title: { text: `Distance (${this.device.linear_label})` },
      });
    } else if (
      this.device.default_display_label.trim().toLowerCase() == "level"
    ) {
      this._chart.series[1].show();
      this._chart.yAxis[0].update({
        title: { text: `Level (${this.device.linear_label})` },
      });
    } else if (
      this.device.default_display_label.trim().toLowerCase() == "weight"
    ) {
      this._chart.series[2].show();
      this._chart.yAxis[0].update({
        title: { text: `Weight (${this.device.weight_label})` },
      });
    } else if (
      this.device.default_display_label.trim().toLowerCase() == "weight ullage"
    ) {
      this._chart.series[3].show();
      this._chart.yAxis[0].update({
        title: { text: `(Weight) (${this.device.weight_label})` },
      });
    } else if (
      this.device.default_display_label.trim().toLowerCase() == "volume"
    ) {
      this._chart.series[4].show();
      this._chart.yAxis[0].update({
        title: { text: `Volume (${this.device.volume_label})` },
      });
    } else if (
      this.device.default_display_label.trim().toLowerCase() == "volume ullage"
    ) {
      this._chart.series[5].show();
      this._chart.yAxis[0].update({
        title: { text: `(Volume) (${this.device.volume_label})` },
      });
    } else if (
      this.device.default_display_label.trim().toLowerCase() == "percent"
    ) {
      this._chart.series[6].show();
      this._chart.yAxis[0].update({ title: { text: `Percent` } });
    } else if (
      this.device.default_display_label.trim().toLowerCase() == "percent ullage"
    ) {
      this._chart.series[7].show();
      this._chart.yAxis[0].update({ title: { text: `(Percent)` } });
    } else if (
      this.device.default_display_label.trim().toLowerCase() ==
      "signal strength"
    ) {
      this._chart.series[8].show();
      this._chart.yAxis[0].update({ title: { text: `(db)` } });
    }

    this._chart.reflow();
    this._chart.redraw(true);
    // }
  }

  public ngOnDestroy() {
    if (this._chart) {
      this._chart.destroy();
    }
    if (this.data) {
      this.data.unsubscribe();
    }
  }

  onDateChange(date: any, isStartDate: boolean) {
    if (isStartDate) {
      this.model.startDate.year = date.year;
      this.model.startDate.month = date.month;
      this.model.startDate.day = date.day;
      this.model.startDate.displayDate = this.showDate(
        this.model.startDate,
        false
      );
      this.model.startDate.isoDate = this.showDate(this.model.startDate);
    } else {
      this.model.endDate.year = date.year;
      this.model.endDate.month = date.month;
      this.model.endDate.day = date.day;
      this.model.endDate.displayDate = this.showDate(this.model.endDate, false);
      this.model.endDate.isoDate = this.showDate(this.model.endDate);
    }
  }

  onTimeChange(event: NgbTimeStruct, isStartDate: boolean) {
    if (isStartDate) {
      this.model.startDate.hour = event.hour;
      this.model.startDate.minute = event.minute;
      this.model.startDate.displayDate = this.showDate(
        this.model.startDate,
        false
      );
      this.model.startDate.isoDate = this.showDate(this.model.startDate);
    } else {
      this.model.endDate.hour = event.hour;
      this.model.endDate.minute = event.minute;
      this.model.endDate.displayDate = this.showDate(this.model.endDate, false);
      this.model.endDate.isoDate = this.showDate(this.model.endDate);
    }
  }

  public showDate(
    date: {
      year?: number;
      month?: number;
      day?: number;
      hour?: number;
      minute?: number;
    },
    returnISODate: boolean = true
  ): string | undefined {
    const { year, month, day, hour, minute } = date;

    // Check if the year, month, and day are valid numbers
    if (!year || !month || !day) return;

    // Create a moment object based on the input date
    const formattedDate = moment({
      year,
      month: month - 1, // Moment.js months are 0-indexed
      day,
      hour: hour || 0,
      minute: minute || 0
    });

    // Return the date in the desired format
    return returnISODate
      ? formattedDate.toISOString() // Returns the ISO date format
      : formattedDate.format('MM/DD/YYYY HH:mm'); // Custom format for non-ISO output
  }

  updateSearch(did?: number) {
    this.ullageContainer = Object.create(this.device);
    this.ullageContainer.setDistance(0);
    this.maxVolume = this.device.max_volume * this.device.getVolumeMultiplier();

    this.loading = true;
    let d = new Date();
    // let timezonediff = d.getTimezoneOffset() * 60 * 1000;/*computers local time off set*/
    let timezonediff = this.device.timezone * 60 * 60 * 1000;

    let start = new Date(this.model.startDate.isoDate);
    let end = new Date(this.model.endDate.isoDate);
    // console.log('start date', start, start.getTime() );
    let _device: Device = Object.create(this.device);

    this.device_id = did;

    // this.series = this.devicesService.getDeviceDistanceSeries( this.device.device_id, start.getTime(),  end.getTime() );
    this.signalSeries = this.devicesService.getDeviceSignalSeries(
      did,
      start.getTime(),
      end.getTime() + 300000
    );
    this.series = this.devicesService.getDeviceDistanceSeries(
      did,
      start.getTime(),
      end.getTime() + 300000
    );

    if (this.device.viewSignal()) {
      let dd = this.signalSeries
        .pipe(
          map((y) => {
            return y.sort((a, b) => {
              return a.timeStamp > b.timeStamp
                ? 1
                : b.timeStamp > a.timeStamp
                ? -1
                : 0;
            });
          })
        )
        .subscribe((x) => {
          this.device.signalStrength = [];
          x.map((d) => {
            this.device.signalStrength.push([
              d.timeStamp + timezonediff,
              Number(d.data),
            ]);
          });
        });
      this.showGraph();
    }
    this.data = this.series
      .pipe(
        map((y) => {
          return y.sort((a, b) => {
            return a.timeStamp > b.timeStamp
              ? 1
              : b.timeStamp > a.timeStamp
              ? -1
              : 0;
          });
        })
      )
      .subscribe((x) => {
        let tmp = _device;
        this.device.distanceSeries = [];
        this.device.levelSeries = [];
        this.device.weightSeries = [];
        this.device.volumeSeries = [];
        this.device.percentSeries = [];

        this.device.uWeightSeries = [];
        this.device.uVolumeSeries = [];
        this.device.uPercentSeries = [];

        x.map((d, index) => {
          tmp.setDistance(d.distance);
          this.device.distanceSeries.push([
            d.timeStamp + timezonediff,
            this.device.round2DecimalPt(d.distance),
          ]);
          this.device.levelSeries.push([
            d.timeStamp + timezonediff,
            this.device.round2DecimalPt(d.level),
          ]);
          this.device.weightSeries.push([
            d.timeStamp + timezonediff,
            this.device.round2DecimalPt(d.weight),
          ]);
          this.device.volumeSeries.push([
            d.timeStamp + timezonediff,
            this.device.round2DecimalPt(d.volume),
          ]);
          this.device.percentSeries.push([
            d.timeStamp + timezonediff,
            this.device.round2DecimalPt(d.percent),
          ]);

          this.device.uWeightSeries.push([
            d.timeStamp + timezonediff,
            this.device.round2DecimalPt(d.weight_ullage),
          ]);
          this.device.uVolumeSeries.push([
            d.timeStamp + timezonediff,
            this.device.round2DecimalPt(d.volume_ullage),
          ]);
          this.device.uPercentSeries.push([
            d.timeStamp + timezonediff,
            this.device.round2DecimalPt(d.percent_ullage),
          ]);
        });
        this.loading = false;
        this.showGraph();
        tmp = null;
        if (this.device.distanceSeries.length > 0) {
          this.data.unsubscribe();
        }
      });
  }

  ngOnInit() {
    if (!this.open) {
      this.updateSearch(this.device.device_id);
    }
  }

  ngOnChanges(changes) {
    try {
      if (
        this.open &&
        changes.open &&
        changes.open.currentValue.open &&
        changes.open.currentValue.device_id == this.device.device_id
      ) {
        let d = new Date();

        let dd = d.getTime() - 24 * 60 * 60 * 1000;

        let date = {
          year: d.getFullYear(),
          month: d.getMonth() + 1,
          day: d.getDate(),
          hour: d.getHours(),
          minute: d.getMinutes()
        }

        let previousDate = {
          year: new Date(dd).getFullYear(),
          month: new Date(dd).getMonth() + 1,
          day: new Date(dd).getDate(),
          hour: new Date(dd).getHours(),
          minute: new Date(dd).getMinutes()
        };

        this.model.startDate = previousDate;
        this.model.endDate = date;

        this.model.startDate.displayDate = this.showDate(previousDate, false);
        this.model.endDate.displayDate = this.showDate(date, false);

        this.model.startDate.isoDate = this.showDate(previousDate);
        this.model.endDate.isoDate = this.showDate(date);

        // this.model.startDate = JSON.parse(JSON.stringify(currentDate));
        // this.model.endDate = JSON.parse(JSON.stringify(currentDate));

        // let displayDate = this.showDate(
        //   JSON.parse(JSON.stringify(currentDate)),
        //   false
        // );
        // let isoDate = this.showDate(JSON.parse(JSON.stringify(currentDate)));

        // this.model.startDate.displayDate = JSON.parse(
        //   JSON.stringify(displayDate)
        // );
        // this.model.endDate.displayDate = JSON.parse(
        //   JSON.stringify(displayDate)
        // );

        // this.model.startDate.isoDate = JSON.parse(JSON.stringify(isoDate));
        // this.model.endDate.isoDate = JSON.parse(JSON.stringify(isoDate));


        this.showGraph();
        this.updateSearch(this.device.device_id);
      }
    } catch (e) {
      console.log("error: line 231", e, this.device.device_id);
    }
  }

  add(device: Device) {
    this.device = device;
    // let timezonediff = (new Date()).getTimezoneOffset() * 60 * 1000;
    let timezonediff = this.device.timezone * 60 * 60 * 1000;
    let time = this.device.timestamp + timezonediff;

    this._chart.series[0].addPoint([
      time,
      this.device.round2DecimalPt(device.distance),
    ]);
    this._chart.series[1].addPoint([
      time,
      this.device.round2DecimalPt(device.level),
    ]);

    this._chart.series[2].addPoint([
      time,
      this.device.round2DecimalPt(device.weight),
    ]);
    this._chart.series[3].addPoint([
      time,
      this.device.round2DecimalPt(device.weight_ullage),
    ]);

    this._chart.series[4].addPoint([
      time,
      this.device.round2DecimalPt(device.volume),
    ]);
    this._chart.series[5].addPoint([
      time,
      this.device.round2DecimalPt(device.volume_ullage),
    ]);

    this._chart.series[6].addPoint([
      time,
      this.device.round2DecimalPt(device.percent),
    ]);
    this._chart.series[7].addPoint([
      time,
      this.device.round2DecimalPt(device.percent_ullage),
    ]);
  }

  private getSeries() {
    const series = [
      { name: "Distance", data: this.device.distanceSeries },
      { name: "Level", data: this.device.levelSeries },
      { name: "Weight", data: this.device.weightSeries },
      { name: "(Weight)", data: this.device.uWeightSeries },
      { name: "Volume", data: this.device.volumeSeries },
      { name: "(Volume)", data: this.device.uVolumeSeries },
      { name: "Percent", data: this.device.percentSeries },
      { name: "(Percent)", data: this.device.uPercentSeries },
      // {  name: `Signal Strength`, data: this.device.signalStrength }
    ];

    series.push({ name: `Signal Strength`, data: this.device.signalStrength });
    return series;
  }
}
