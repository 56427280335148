import { Injectable } 							from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';


import { environment} from '../../environments/environment';
import { UtilService } from './util.service';

import { Rules } from '../model/rules';

@Injectable()
export class RulesService {
	public hasError:Subject<boolean> = new Subject<boolean>();
	public message:Subject<string> = new Subject<string>();

	private _rules: Subject<Rules[]> = new Subject<Rules[]>();
	public rules: Observable<Rules[]> 		= this._rules.asObservable();


	private _llrules: Subject<Rules> = new Subject<Rules>();
	public llrules: Observable<Rules> 		= this._llrules.asObservable();

	private _lrules: Subject<Rules> = new Subject<Rules>();
	public lrules: Observable<Rules> 		= this._lrules.asObservable();

	private _hrules: Subject<Rules> = new Subject<Rules>();
	public hrules: Observable<Rules> 		= this._hrules.asObservable();

	private _hhrules: Subject<Rules> = new Subject<Rules>();
	public hhrules: Observable<Rules> 		= this._hhrules.asObservable();

	private _statusrules: Subject<Rules> = new Subject<Rules>();
	public statusrules: Observable<Rules> 		= this._statusrules.asObservable();

  	private rulesUrl = environment.apiurl + '/location/getRules';
	constructor (private util: UtilService, private router: Router) {}

	public getRules(id): void {
		this.util._getData( this.rulesUrl, {device_id: id})
			.pipe(map(ret => {
				return (ret.data || []).map(((item) => {
					Object.keys(item).forEach((key) => {
						item[key] = item[key] != null ? item[key] : '';
					});
					return item;
				}))
			}))
			.subscribe( gJSON =>{
									gJSON.forEach(x=>{
										if(x.type.trim().toUpperCase() == 'LL'){
											this._llrules.next( x );
										}else if(x.type.trim().toUpperCase() == 'L'){
											this._lrules.next( x );
										}else if(x.type.trim().toUpperCase() == 'H'){
											this._hrules.next( x );
										}else if(x.type.trim().toUpperCase() == 'HH'){
											this._hhrules.next( x );
										}else if(x.type.trim().toUpperCase() == 'STATUS'){
											this._statusrules.next( x );
										}
									})
									this._rules.next( gJSON );
								}
						)
	}
	
	public saveRules(body,method: 'saveNotifications' | 'saveAlarmRules',device_id): void {
		let url = environment.apiurl + '/location/' + method;
		this.util._postData( url,body )
			.pipe(map(ret=>ret.data))
			.subscribe( 
							()=>{
								this.hasError.next(false); 
								if(method == 'saveAlarmRules'){
									this.message.next('Rules are saved successfully');									
								}else{
									this.message.next('Notifications are saved successfully');	
								}
							},
							(err)=>{
								this.hasError.next(true); 
								if(err.message){
									this.message.next(err.message);
								}else if(method == 'saveAlarmRules'){
									this.message.next('There was an error saving rules, Please try again.');
								}else{
									this.message.next('There was an error saving notifications, Please try again.');
								}
							},
							()=>console.log('saving completed')
						)
	}

	public sendTestNotification(body,device_id): void {
		let url = environment.apiurl + '/location/sendTestNotification';
		this.util._postData( url,body )
			.pipe(map(ret=>ret.qry))
			.subscribe( 
							res=>{
								if( res.success == '' || res.success == 1 ){
										this.hasError.next(false); 
										this.message.next('Test message was sent successfully')
								}else{
									this.hasError.next(true); 
									this.message.next('There was an error sending test message.')
									// this.router.navigateByUrl('/administration/user/edit/' + res.user_id);
								}
								// this.router.navigateByUrl('/administration/silo/edit/' + device_id);
							},
							()=>{
									console.log('Error sending test message');
									this.hasError.next(true); 
									this.message.next('There was an error sending test message.') 
								},
							()=>{console.log('Sending message completed')}
						)
	}

	public sendCustomerService(body): void {
		let url = environment.apiurl + '/location/sendCustomerService';
		this.util._postData( url,body )
			.pipe(map(ret=>ret.qry))
			.subscribe( 
							res=>{
								if( res.success == '' || res.success == 1 ){
										this.hasError.next(false); 
										this.message.next('Someone will be contacting you shortly with a reply to your comment.')
								}else{
									this.hasError.next(true); 
									this.message.next('There was an error sending your comment or question.')
									// this.router.navigateByUrl('/administration/user/edit/' + res.user_id);
								}
								this.router.navigateByUrl('/support');
							},
							()=>{
									console.log('Error sending test message');
									this.hasError.next(true); 
									this.message.next('TThere was an error sending your comment or question.') 
								},
							()=>{console.log('Sending message completed')}
						)
	}

	
}
